<template>
    <div>
        <b-sidebar backdrop id="changeNote" width="50rem" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right  shadow >
            <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>{{$parent.lang.changeNote}}</span>
                </div>
                <div @click="hide" id="CloseIt" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                    <span>{{$parent.lang.close}}</span>
                </div>
            </div>
            <v-divider></v-divider>
            <div class="px-3 py-2" style="direction:rtl">
                <v-row>
                    <v-col cols="12">
                        <label>{{ $parent.lang.notes }}</label>
                        <b-form-textarea
                            class="inborder"
                            v-model="notes"
                            style="height:150px;"
                            ></b-form-textarea>
                    </v-col>
                </v-row>
            </div>
    </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" variant="success" @click='addUser()' class="ma-2" style="width:100px;">{{ $parent.lang.update }}</b-button>
                </div>
            </template>
        </b-sidebar>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import {SnotifyPosition, SnotifyStyle} from 'vue-snotify';
import axios from 'axios';
export default {
    data() {
        return {
            invid: '',
            notes: ''
        }
    },
    methods: {
        modifyIt(note){
            this.invid = note.id,
            this.notes = note.notes
        },
        addUser(){
            const post = new FormData();
            post.append("type",'changeInvoiceNote');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[invid]",this.invid);
            post.append("data[notes]",this.notes);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (resposnse) => {
                    const res = resposnse.data;
                    if(res.error.number == 200){
                        document.getElementById('CloseIt').click();
                        this.$parent.getInvoice()
                        this.$snotify.create({
                            title: 'اضافة / تعديل',
                            body: 'تمت العملية بنجاح',
                            config: {
                                position: SnotifyPosition.leftTop,
                                type: SnotifyStyle.success,
                                timeout: 10000,
                                showProgressBar: true,
                                closeOnClick: true,
                            }
                        })
                    }else{
                        this.$snotify.error({
                            title: 'اضافة / تعديل',
                            body: 'حصل خطأ اثناء الاضافة/التعديل',
                            config: {
                                position: SnotifyPosition.leftTop,
                                type: SnotifyStyle.success,
                                timeout: 10000,
                                showProgressBar: true,
                                closeOnClick: true,
                            }
                        })
                    }
                }
            )
        }
    },
}
</script>